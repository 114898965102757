<script>
	import Modal from 'src/components/common/modal.svelte';
	let modal;
</script>

<div class="flex flex-1 flex-col space-y-5 pt-24 px-14">
	<div class="flex flex-col-reverse md:flex-row md:items-center md:justify-between ">
		<a href="https://relato-de-problemas.paperform.co/" target="_blank" rel="noopener noreferrer">
			<button class="flex space-x-4 md:mt-0 mt-10 justify-center">
				<div>
					<img src="/img/footer/icon_atention.svg" alt="" />
				</div>
				<p class="text-gray-brand-200">Relatar um problema</p>
			</button>
		</a>
		<div class="flex flex-row justify-between">
			<div class="w-[200px]">
				<a href="https://txm.business/">
					<img src="/img/footer/logo_txm_footer.svg" alt="" />
				</a>
			</div>
			<a href="https://cocreationlab.com.br/">
				<div class="md:hidden"><img src="/img/footer/logo_cocreation_footer.svg" alt="" /></div>
			</a>
		</div>
		<a href="https://cocreationlab.com.br/">
			<div class="hidden md:flex"><img src="/img/footer/logo_cocreation_footer.svg" alt="" /></div>
		</a>
	</div>
	<div>
		<p class="  text-center text-neutral-300 hidden md:block">
			Copyright © TXM Business - 2019-2029
		</p>
	</div>
</div>
<!-- <Modal bind:this={modal} outerClass="w-2/3 items-center ">
	<div class="flex flex-col p-10 w-full h-full modal gap-4">
		<div>
			<h1 class="text-3xl">Relate seu Problema</h1>
			<h2 class="text-xl">Assim que possível verificaremos a melhor forma de resolvê-lo.</h2>
		</div>
		<div class="flex flex-col gap-1">
			<span class="text-lg"
				>Em qual página da plataforma você estava quando o problema ocorreu?</span
			>
			<input class="w-full input p-2 rounded-2xl" type="text" />
		</div>
		<div class="flex flex-col gap-1">
			<span class="text-lg"
				>Qual foi o problema (o que você estava fazendo e o que não funcionou)?</span
			>
			<textarea class="w-full input p-2 rounded-2xl" type="text" />
		</div>
		<div class="flex flex-col gap-1">
			<span class="text-lg">Possui algo a mais para relatar?</span>
			<textarea class="w-full input p-2 rounded-2xl" type="text" />
		</div>
		<div class="flex justify-end gap-4">
			<button class="button" on:click={modal.close}>Cancelar</button>
			<button class="button">Enviar</button>
		</div>
	</div>
</Modal> -->
